import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AboutAsset/AboutAsset.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetLogo/AssetLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsList/AssetListItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsList/AssetsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/AssetsSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/AssetsSearchButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/AssetsSearchModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/TopPerformersCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/TopPerformersCards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetUnavailable/AssetUnavailable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/DefaultErrorMessage/DefaultErrorMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/DevConsole/DevConsole.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Education/AvailableTokenRow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Education/EducationCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Education/LearnSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentAsset/InvestmentAsset.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentCTA/InvestmentCTA.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentPortalDisclosure/InvestmentPortalDisclosure.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentsList/InvestmentCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentsList/InvestmentsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentStat/InvestmentStat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestModal/InvestAssetsSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestModal/InvestModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/LatestTrade/LatestTrade.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/LatestTransactions/LatestTransactions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Onboarding/CreateAccountCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Onboarding/IntroductionCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Onboarding/Modal/Modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Onboarding/OnboardingStep.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/PageLayout/PageLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/PerformanceOverTime/PerformanceOverTime.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Providers/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStoreContext"] */ "/vercel/path0/app/_core/components/Providers/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LabelTd","ValueTd"] */ "/vercel/path0/app/_core/components/SingleTransactionTable/SingleTransactionTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/StatementRow/StatementRow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/SystemStatusMessage/SystemStatusMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TimeRangeSelector/TimeRangeSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/ActiveFilters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/AssetTransactionHistory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/TransactionAccordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/TransactionAccordionList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/TransactionHistoryFilterModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/TransactionHistoryTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionStatus/TransactionStatus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TrendIndicator/TrendIndicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
